import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables';
import { OFFERS } from '@fingo/lib/constants/shopping-carts';
import FingoMainViewV2 from '@fingo/lib/components/layout/FingoMainViewV2';
import ShoppingCartSearchHeader from '@fingo/lib/components/headers/ShoppingCartSearchHeader';
import { MY_OFFERS } from '@fingo/lib/graphql';
import { useSelectedCompany } from '@fingo/lib/hooks';
import NoRowsGeneric from '@fingo/lib/components/text/NoRowsGeneric';
import { COLUMNS, SUBTITLE, TITLE } from '../constants/offers';
import OffersSummary from '../components/offers/OffersSummary';

const Offers = () => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const offerIds = shoppingCartOffers.map((offer) => offer.invoiceId);
  const selectedCompany = useSelectedCompany();
  return (
    <FingoMainViewV2
      id="offers"
      apolloQuery={{
        query: MY_OFFERS,
        orderBy: { field: 'pk', sort: 'desc' },
        variables: {
          hasValidOffer: true,
          companyId: selectedCompany?.id,
        },
      }}
      slots={{
        header: ShoppingCartSearchHeader,
        summary: OffersSummary,
      }}
      slotProps={{
        headerProps: {
          title: TITLE,
          subtitle: SUBTITLE,
          shoppingCartType: OFFERS,
        },
        summaryProps: { open: shoppingCartOffers.length !== 0 },
        tableProps: {
          columns: COLUMNS,
          checkboxSelection: true,
          noRowsMessage: NoRowsGeneric,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => {
            const newSelectedInvoices = ids.map((id) => {
              const previousSelectedInvoice = (
                shoppingCartOffers.find(({ invoiceId }) => invoiceId === id)
              );
              return previousSelectedInvoice || { invoiceId: id, dateToPay: null };
            });
            shoppingCartOffersVar(newSelectedInvoices);
          },
          selectionModel: offerIds,
        },
      }}
    />
  );
};

export default Offers;
